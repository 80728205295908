// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const resources = [
    { img: props.data.img1 },
    { img: props.data.img2 },
    { img: props.data.img3 },
    { img: props.data.img4 },
  ]

  const sliderConf = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    customPaging: (i) => {
      return (
        <a aria-label={`Go to slide ${i + 1}`} role="link">
          <GatsbyImage image={getImage(resources[i].img)} />
        </a>
      )
    },
    dotPosition: 'bottom',
  }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this book</h1>
      <p className="dropcap">
        <span>S</span>ri Aurobindo and Mother came to bring the beginning of the
        next stage in human evolution. They are spiritual teachers. They taught
        that our cosmos exists because the Supreme All-Consciousness and
        All-Love wanted to manifest Itself. In order to do this, it became less
        and less conscious. As All-Consciousness gradually turned into
        unconsciousness, the mental planes of existence came into being, then
        the vital-life planes, until finally it became unconscious, dense
        physical matter. This is what constitutes, our mind, our vital (our life
        energies, emotions and impulses), and our physical body. Our individual
        soul is our inmost, most subtle part of the All-Consciousness. It
        retains its conscious contact with its origin.
      </p>
      <p className="text-indent">
        The history of man’s evolution is the All-Consciousness continuing to
        fully realise itself once again in time and space. Human beings are the
        most evolved creatures on earth. As Unconsciousness turned again into
        Consciousness, the mind was the last to evolve out of life and body. Man
        is a mental being. He is ruled by his own mind and he is subject to its
        limitations. Man’s mind isn’t capable of encompassing the Supreme
        All-Consciousness and All-Love. Therefore the coming evolutionary stage
        will bring capacities and abilities beyond the mind. Sri Aurobindo calls
        the next stage “Supramental” because man will develop beyond the
        functions and capacities and limitations of the mind. He calls the new
        consciousness the “Supramental Consciousness”.
      </p>
      <p className="text-indent">
        Sri Aurobindo wrote; “To be aware wholly and integrally of oneself and
        of all the truth of one’s being is the necessary condition of true
        possession of existence. This self-awareness is what is meant by
        spiritual knowledge; the essence of spiritual knowledge is an intrinsic
        self-existent consciousness; all its action of knowledge, indeed all its
        action of any kind must be that consciousness formulating itself.”
      </p>
      <p className="text-indent">
        The All-Consciousness which evolved into body, life and mind will now
        emerge out of them. This necessarily means a complete change of all
        human parts from the most subtle to the most physical. This radical
        change is what Sri Aurobindo and Mother call the “Supramental
        Transformation”. They explain that it is something entirely new, and
        therefore it is not possible to know everything about it. They did
        everything they could to prepare mankind for this new and unknown
        change.
      </p>
      <p className="text-indent">
        They both experienced this new powerful, all-containing consciousness
        emerging in themselves. They teach from experience and realisation.
        Their writings carry their consciousness. They saw that the new
        consciousness is going to emerge in a wide number of people. There will
        be a rapid and complete change, compared with the pace of evolution
        known in human history.
      </p>
      <p className="text-indent">
        Sri Aurobindo and Mother lived and worked in India, where from ancient
        times people have been conscious of their true source and the goal of
        their existence. Sri Aurobindo found that the knowledge and experience
        of the All-Consciousness latent in humanity, and its evolution in human
        beings, was known to the earliest Indian Rishis, sages, seers and yogis.
      </p>
      <p className="text-indent">
        Sri Aurobindo and Mother chose an environment dedicated to human
        progress and change of consciousness. They created an Indian “Ashram”,
        the traditional place for people to work for higher consciousness. The
        first people were those who were drawn to them, to live in the Sri
        Aurobindo Ashram and devote their lives to their own faster evolution.
        Sri Aurobindo and Mother came for world-wide change, so everything they
        taught was carefully printed in books. It is made available for all of
        humanity. Today people all over the world are working in themselves for
        this change.
      </p>
      <p className="text-indent">
        This book, “Sri Aurobindo and Mother, The Supramental Transformation”,
        is a collection of their writings on the subject. There are quotations
        from Sri Aurobindo’s poetry, his essays, and his many books. There are
        quotations from Mother’s classes and the recordings of her own
        experiences of the coming Supramental consciousness. The selections
        carry the consciousness with which they were written and give an
        understanding and experience of the consciousness itself.
      </p>
      <p className="text-indent">
        <Link to="/introduction">Continue reading...</Link>
      </p>
      <h2>About Integral Yoga Talks</h2>
      <p className="dropcap">
        <span>T</span>his book is part of the Integral Yoga Talks project. The
        Integral Yoga Talks project is a website offering which will publish
        talks by Loretta Shartsis on Sri Aurobindo and Mother, both in audio and
        video, and will also make available Loretta’s book compilations as
        web-books. The project is scheduled to be released in stages from
        2022–24 under the umbrella of Prisma’s new publications format of
        gift-economy publishing.
      </p>
      <p className="text-indent">
        Over the years, Loretta has given public talks on Sri Aurobindo and
        Mother both in Auroville and abroad, and has recorded two series of
        talks for Auroville Radio. She has published four book-compilations of
        their works. These on-going efforts will now be available worldwide on
        the website{' '}
        <Link to="https://www.integral-yoga-talks.com/en/">
          www.integral-yoga-talks.com
        </Link>
        .
      </p>
      <h2>About Loretta Shartsis</h2>
      <p className="dropcap">
        <span>L</span>oretta Shartsis came to the Sri Aurobindo Ashram in
        February 1972, because she wanted to meet Mother. It was Sri Aurobindo’s
        100th Centenary year. Loretta met Mother in March and the next day,
        without knowing how it happened, she found herself living at the centre
        of Auroville, with her own room in the Matrimandir Worker’s Camp. From
        that time, her life has been devoted to Mother and Sri Aurobindo. Fifty
        years later, for Sri Aurobindo’s 150th Birth Centenary, the world will
        have the results of her dedicated teaching about Mother and Sri
        Aurobindo.
      </p>
      <p className="text-indent">
        Loretta came to Mother after growing up with a life of work and
        education as a visual artist. Later she became an attorney, doing
        Supreme Court and Court of Appeals Research and Presentation and court
        trials. In her teaching work she combines a life of aesthetic pursuits
        and the high quality research and public presentation required in a
        court of law.
      </p>
      <h2>The print book</h2>
      <div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        <span>Y</span>ou can buy the paperback version of the book{' '}
        <i>{about.altTitle}</i> at a fixed price. The author and the publisher
        have also chosen to offer the book to read online without a pay-wall or
        advertisement. This is not for free, but available on a Gift-Economy and
        Pay-What-Feels-Right basis. It is an invitation to give what feels right
        for the value you've received from the book and/or the support you want
        to give us to continue doing our work. Publishing in this manner is an
        experiment in abundance where we trust that when we give, we will also
        receive. We want you to enjoy the book, learn from the book, but also
        support the book. If it was worth your time, please consider buying the
        book or contributing.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        <span>S</span>ince 1988 Prisma Books of Auroville publishes good
        old-fashioned, beautifully curated books. Prisma Books offers full
        publishing services in design, editorial, printing, artwork,
        illustrations, photography, digital media, exhibition and curatorship.
      </p>
      <p className="text-indent">
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
